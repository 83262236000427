<template>
  <div class="pwdChange">
    <van-form>
<!--      <van-field :rules="[{ required: true, message: '请输入原手机号' }]" v-model="dataForm.oldMobile" placeholder="请输入原手机号" />-->
      <van-field :rules="[{ required: true, message: '请输入新手机号' }]" v-model="dataForm.newMobile" placeholder="输入手机号" class="mobileField" />
      <div class="leftTxt"><span>+86</span><span>|</span></div>
      <van-field :rules="[{ required: true, message: '请输入验证码' }]" v-model="dataForm.code" placeholder="输入验证码" >
        <template #button>
          <van-button  @click="getCode" ><p :style="isCode ? 'color:#256EF8' : 'color: #ccc'">获取验证码{{isCode ? '' : num}}</p></van-button>
        </template>
      </van-field>
      <div style="position:relative;">
        <van-button class="btn" round type="info" @click="changePwd">确认修改</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import {isMobile} from '@/utils/validate'
export default {
  data () {
    return {
      dataForm: {
        oldMobile: '',
        newMobile: '',
        code: '',
      },
      num: 30,
      isCode: true,
      timer: null
    }
  },
  methods: {
    changePwd () {
      // if (this.dataForm.oldMobile.trim() === '') {return this.$toast.fail('请输入旧手机号')}
      if (this.dataForm.newMobile.trim() === '') {return this.$toast.fail('请输入手机号')}
      if (this.dataForm.code.trim() === '') {return this.$toast.fail('请输入验证码')}
      // this.$http({
      //   url: this.$http.adornUrl('/general/verify/verifyCode'),
      //   method: 'post',
      //   params: this.$http.adornParams({
      //     token: this.dataForm.newMobile,
      //     code: this.dataForm.code
      //   },false)
      // }).then(({data}) => {
      //   if (data.code == 0) {
      //     setTimeout(() => {
            this.$http({
              url: this.$http.adornUrl('/wxapp/user/jmgg/updateMobile'),
              method: 'post',
              params: this.$http.adornParams({
                id: this.$globalData.userInfo.userId,
                newMobile: this.dataForm.newMobile,
                code: this.dataForm.code
              },false)
            }).then(({data}) => {
              if (data.code == 0) {
                this.$toast.success('修改成功')
                this.$router.go(-1)
              } else {
                this.$toast.fail(data.msg)
              }
            })
      //     })
      //   } else {
      //     this.$toast.fail(data.msg)
      //   }
      // })
    },
    getCode () {
      if (this.dataForm.newMobile.trim() === '') {return this.$toast.fail('请输入手机号')}
      if (!isMobile(this.dataForm.newMobile)) {return this.$toast.fail('请输入正确的手机号')}
      if (this.isCode) {
        this.isCode = false
        this.$http({
          url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
          method: 'post',
          params: this.$http.adornParams({
            mobile: this.dataForm.newMobile,
            needExist: 1,
            force: true
          },false)
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success('发送成功')
          } else {
            this.$toast.fail(data.msg)
          }
        })
        this.timer = setInterval(() => {
          this.num--
          if (this.num === 0) {
            this.isCode = true
            clearInterval(this.timer)
            this.num = 30
          }
        }, 1000)
      }
    }
  }
}
</script>
<style scoped>
.pwdChange >>> .van-field__control {
  text-align: left !important;
}
.pwdChange .mobileField >>> .van-field__control {
  text-align: left !important;
  padding: 0 0 0 90px;
}
.pwdChange >>> .van-button--default {
  border: none !important;
}
.btn {
  position: fixed;
  left: 50%;
  bottom: 40px;
  width: 690px;
  height: 88px;
  background: #4581F8;
  border-radius: 8px;
  transform: translateX(-50%);
}
.pwdChange >>> .van-field__control::placeholder {
  color: #aaaaaa;
  font-size: 30px;
  /*padding-left: 90px;*/
}
.leftTxt {
  position: absolute;
  top: 25px;
  left: 30px;
  font-size: 30px;
  color: #333333;
}
.leftTxt span:last-child {
  position: absolute;
  left: 65px !important;
  padding-bottom: 5px;
  color: #EEEEEE;
}
</style>
